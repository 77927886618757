<script setup lang="ts">
import type { ComponentConditionalLogicProps } from "./ComponentConditionalLogic.props"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"

const props = defineProps<ComponentConditionalLogicProps>()

const isLogged = useIsLogged()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const cmsComponent = computed(() =>
  props.content?.name ? `Cms${props.content?.name}` : null
)

const isComponent = (name: string) => name === cmsComponent.value

const checkStatus = (status: string) => {
  switch (status) {
    case "User logged":
      return !!isLogged.value
    case "User not logged":
      return !isLogged.value
    case "First visit":
      return !useStatefulCookie("already-visited").value
    case "Second visit":
      return !!useStatefulCookie("already-visited").value
    case "No orders yet":
      return user.value ? user?.value.status === "prospect" : false
    default:
      return true
  }
}

const showComponent = computed(() => {
  // filtering out null discrepancies
  const filteredStatuses = props.status?.filter((s) => s !== null) as string[]
  // showing by default if no status is set
  if (!props.status || !filteredStatuses.length) return true
  return filteredStatuses.map(checkStatus).every((status) => status)
})
</script>

<template>
  <div class="cms-component-conditional-logic" v-show="showComponent">
    <CmsComponentAlgoliaRailsProducts
      v-if="isComponent('CmsComponentAlgoliaRailsProducts')"
      v-bind="content?.props"
    />
    <CmsComponentAlgoliaTabsCarousel
      v-if="isComponent('CmsComponentAlgoliaTabsCarousel')"
      v-bind="content?.props"
      :preload-images="preloadImages"
    />
    <CmsComponentBannerCategoriesOfTheMoment
      v-if="isComponent('CmsComponentBannerCategoriesOfTheMoment')"
      v-bind="content?.props"
    />
    <CmsComponentBannerOurBrands
      v-if="isComponent('CmsComponentBannerOurBrands')"
      v-bind="content?.props"
    />
    <CmsComponentEditorialBanner
      v-if="isComponent('CmsComponentEditorialBanner')"
      v-bind="content?.props"
      :preload-images="preloadImages"
    />
    <CmsComponentPromoCode
      v-if="isComponent('CmsComponentPromoCode')"
      v-bind="content?.props"
    />
    <CmsComponentRecapordercart
      v-if="isComponent('CmsComponentRecapordercart')"
      v-bind="content?.props"
    />
    <CmsComponentSuperDiscountProducts
      v-if="isComponent('CmsComponentSuperDiscountProducts')"
      v-bind="content?.props"
    />
  </div>
</template>
